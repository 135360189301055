<script lang="ts">
  import Header from "./components/Header.svelte";
  import Banner from "./components/Banner.svelte";
  import Footer from "./components/Footer.svelte";
  import NewNote from "./components/NewNote.svelte";
  import SavedNote from "./components/SavedNote.svelte";
  import { copyToClipboard } from "./utils";
  import { secret } from "./stores";

  let _secret = "",
    _urlSecret = "";

  secret.subscribe((value) => {
    _secret = value;
  });

  const urlParams = new URLSearchParams(window.location.search);
  _urlSecret = urlParams.get("secret");

  const generateSecretLink = (_secret) => {
    return `https://www.vanishing.ink/?secret=${_secret}`;
  };
</script>

<main>
  <Header />
  <Banner />
  {#if _urlSecret}
    <SavedNote secret={_urlSecret} />
  {:else if _secret}
    <p>Here is the secret link to your note. Share it carefully.</p>
    <pre>{generateSecretLink(_secret)}</pre>
    <div>
      <button on:click={copyToClipboard(generateSecretLink(_secret))}
        >Copy Link</button
      >
    </div>
  {:else}
    <NewNote />
  {/if}
</main>

<Footer />

<style>
  main {
    margin: 100px auto 0;
    width: 500px;

    flex: 1 0 auto;
  }

  @media only screen and (max-width: 768px) {
    main {
      margin: 50px auto;
      width: 100%;
    }
  }
</style>
