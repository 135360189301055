<script>
  import { saveNote } from "../notes";
  import { secret } from "../stores";

  let _note = "",
    _advancedOpen = false,
    _expirationHours = "24",
    _burnAfterReading = true;

  const save = () => {
    saveNote({
      note_text: _note,
      expiration_hours: Number(_expirationHours),
      delete_on_read: _burnAfterReading,
    }).then((s) => {
      secret.set(s);
    });
  };
</script>

<textarea
  name="note"
  id="note"
  cols="30"
  rows="10"
  placeholder="Top secret stuff goes here"
  bind:value={_note}
/>
<div>
  <button on:click={save} disabled={!_note}>Save</button>
  <button on:click={() => (_advancedOpen = !_advancedOpen)}>Advanced</button>
  <div class={_advancedOpen ? "advancedOpen" : "advancedClosed"}>
    <p>🚨 Warning: Experimental</p>
    <label for="expiration">Expiration</label>
    <select name="expiration" id="expiration" bind:value={_expirationHours}>
      <option value="24" selected>24 Hours</option>
      <option value="48">48 Hours</option>
      <option value="168">1 Week</option>
    </select>
    <label for="burnAfterReading">Delete After Reading</label>
    <input
      type="checkbox"
      name="burnAfterReading"
      id="burnAfterReading"
      bind:checked={_burnAfterReading}
    />
  </div>
</div>

<style>
  textarea {
    width: 100%;
  }

  .advancedOpen {
    display: block;
  }

  .advancedClosed {
    display: none;
  }
</style>
