<script></script>

<footer>
  <p>
    👋 <a href="https://twitter.com/nickflynndev" target="_blank">Nick</a> is building
    this (and he loves feedback)
  </p>
</footer>

<style>
  footer {
    text-align: center;

    flex-shrink: 0;
  }
</style>
