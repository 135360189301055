export const saveNote = async (note) => {
  return await fetch("/api/note", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      note_text: note.note_text,
      expiration_hours: note.expiration_hours,
      delete_on_read: note.delete_after_reading,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data.secret;
    })
    .catch((err) => console.log(err));
};

export const getNote = async (secret) => {
  return await fetch(`/api/note?secret=${secret}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.note_text;
    })
    .catch((err) => console.log(err));
};
