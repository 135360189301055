export const getCount = async () => {
  return await fetch(`/api/count`, {
    method: "GET",
    headers: {
      "Content-Type": "text/plain",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((err) => console.log(err));
};
