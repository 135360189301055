<script></script>

<div>
  <a href="/" title="Start Over">
    <img src="/images/logo.svg" alt="Vanishing Ink" />
  </a>
</div>

<style>
  img {
    width: 50px;
    height: 50px;
  }
</style>
