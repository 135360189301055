<script>
  import { getNote } from "../notes";
  import { copyToClipboard } from "../utils";

  export let secret;
  let _note;

  const get = () => {
    getNote(secret).then((n) => {
      _note = n;
    });
  };
</script>

{#if _note}
  <div>
    <pre>{_note}</pre>
    <button on:click={copyToClipboard(_note)}>Copy</button>
  </div>
{:else}
  <p>
    Are you ready to read this note? It will be permanently deleted once you do.
  </p>
  <div>
    <button on:click={get}>Read</button>
  </div>
{/if}

<style></style>
