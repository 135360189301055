<script>
  import { getCount } from "../count";

  let _examples = [
      "passwords",
      "API keys",
      "account numbers",
      "recovery codes",
      "secrets",
      "magic spells",
    ],
    _index = 0,
    _count;

  getCount().then((c) => {
    _count = c;
  });

  window.setInterval(() => {
    if (_index < _examples.length - 1) {
      _index++;
    } else {
      _index = 0;
    }
  }, 3000);
</script>

<div>
  <p>Securely share <span>{_examples[_index]}</span></p>
  {#if _count}
    <p>{_count} vanishing notes created</p>
  {/if}
</div>

<style>
  span {
    color: #ac00f8;
    font-style: italic;
  }
</style>
